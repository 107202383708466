import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
import spinnerReducer from '../../Service/State/spinnerSlice/spinnerSlice';
import stepReducer from '../../Service/State/stepSlice/stepSlice';
import authReducer from '../../Service/State/authSlice/authSlice';
import userInfoReducer from '../../Service/State/userInfoSlice/userInfoSlice';
import toastReducer from '../../Service/State/toasterSlice/toasterSlice';
import confirmationReducer from '../../Service/State/confirmtionSlice/confirmtionSlice';
import eventReducer from '../../Service/State/events/eventsSlice';
import profileReducer from '../../Service/State/profile/profile.slice';

export const store = configureStore({
  reducer: {
    spinner: spinnerReducer,
    step: stepReducer,
    auth: authReducer,
    userInfo: userInfoReducer,
    toast: toastReducer,
    confirmation: confirmationReducer,
    event: eventReducer,
    profile: profileReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  // .concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
