import moment from 'moment';
import React, { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Auth from '../../Pages/Auth/Auth';
import ForgotPassword from '../../Pages/Auth/ForgotPassword/ForgotPassword';
import Login from '../../Pages/Auth/Login/Login';

import ResetPassword from '../../Pages/Auth/ResetPassword/ResetPassword';

import { selectAuth } from '../../Service/State/authSlice/authSlice';
// import { selectUserInfo } from '../../Service/State/userInfoSlice/userInfoSlice';
import { useAppSelector } from '../Store/hooks';

const Routes = () => {
  // get the auth state to guard my home route
  const auth = useAppSelector(selectAuth);
  const token: string | null = localStorage.getItem('token');
  // get my role to guard and nested route
  // const role = useAppSelector(selectUserInfo);

  // lazy loading my all components
  const Home = lazy(() => import('../../Pages/Home/Home'));
  // const User = lazy(() => import('../../Pages/User/User'));
  // const Survey = lazy(() => import('../../Pages/Survey/Survey'));
  // const Preview = lazy(() => import('../../Pages/Preview/Preview'));
  // const Results = lazy(() => import('../../Pages/Results/Results'));
  const NotFound = lazy(() => import('../../Pages/NotFound/NotFound'));
  const Dashboard = lazy(() => import('../../Pages/Dashboard/Dashboard'));

  const AddBrand = lazy(() => import('../../Pages/Brands/AddBrand'));
  const BrandsList = lazy(() => import('../../Pages/Brands/BrandsList'));
  const PreviewBrand = lazy(() => import('../../Pages/Brands/PreviewBrand'));

  const AddCompany = lazy(() => import('../../Pages/Companies/AddCompany'));
  const CompaniesList = lazy(() => import('../../Pages/Companies/CompaniesList'));
  const PreviewCompany = lazy(() => import('../../Pages/Companies/PreviewCompany'));

  const SpeakersList = lazy(() => import('../../Pages/SpeakersList/SpeakersList'));
  const AddSpeaker = lazy(() => import('../../Pages/SpeakersList/AddSpeaker'));

  const AddEvent = lazy(() => import('../../Pages/Events/AddEvent'));

  const EventInformation = lazy(() => import('../../Pages/Events/EventSteps/EventInformation'));
  const SessionsDates = lazy(() => import('../../Pages/Events/EventSteps/SessionsDates'));
  const EventDetails = lazy(() => import('../../Pages/Events/EventSteps/EventDetails'));

  const EventsList = lazy(() => import('../../Pages/Events/EventsList'));
  // const PreviewEvent = lazy(() => import('../../Pages/Events/PreviewEvent'));

  // const AddUser = lazy(() => import('../../Pages/Users/AddUser'));
  // const UsersList = lazy(() => import('../../Pages/Users/UsersList'));
  /**
   * @todo render my routes and my child routes
   */
  const routes = useRoutes([
    {
      path: 'login',
      element: token && JSON.parse(token).access_token ? <Navigate to="/" /> : <Auth />,
      children: [
        { path: '', element: <Login /> },
        { path: 'reset-password/:token', element: <ResetPassword /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
      ],
    },
    {
      path: '/',
      element:
        (token && moment(new Date(JSON.parse(token).expired_at)).isBefore(moment())) || auth.isAuth || true ? <Home /> : <Navigate to="login" />,
      children: [
        { path: '/', element: <Dashboard /> },
        // {
        //   path: 'user',
        //   element: role.role !== 'user' ? <User /> : <Navigate to="/login" />,
        // },

        {
          path: 'companies',
          children: [
            { path: '', element: <CompaniesList /> },
            { path: 'add', element: <AddCompany /> },

            { path: 'edit/:id', element: <AddCompany /> },
            { path: 'edit', element: <Navigate to="/companies" /> },
            // { path: 'list', element: <CompaniesList /> },
            { path: ':id', element: <PreviewCompany /> },
          ],
        },
        // {
        //   path: 'users',
        //   children: [
        //     { path: '', element: <UsersList /> },
        //     { path: 'add', element: <AddUser /> },

        //     { path: 'edit/:id', element: <AddUser /> },
        //     { path: 'edit', element: <Navigate to="/users" /> },
        //     // { path: 'list', element: <CompaniesList /> },
        //     { path: ':id', element: <PreviewCompany /> },
        //   ],
        // },
        {
          path: 'brands',
          children: [
            { path: '', element: <BrandsList /> },
            { path: 'add', element: <AddBrand /> },
            { path: 'edit/:id', element: <AddBrand /> },
            { path: 'edit', element: <Navigate to="/brands" /> },
            { path: ':id', element: <PreviewBrand /> },
          ],
        },
        {
          path: 'speakers',
          children: [
            { path: '', element: <SpeakersList /> },
            { path: 'add', element: <AddSpeaker /> },
            { path: 'edit', element: <Navigate to="/speakers" /> },
            { path: 'edit/:id', element: <AddSpeaker /> },
          ],
        },
        {
          path: 'events',
          children: [
            { path: '', element: <EventsList /> },
            {
              path: 'add',
              element: <AddEvent />,
              children: [
                { path: '', element: <EventInformation /> },
                { path: 'event-info/:id', element: <EventInformation /> },
                { path: 'event-calender', element: <SessionsDates /> },
                { path: 'event-calender/:id', element: <SessionsDates /> },
                { path: 'event-pages/:id', element: <EventDetails /> },
              ],
            },

            // { path: ':id', element: <PreviewEvent /> },
          ],
        },
        // { path: 'survey', element: <Survey /> },
        // { path: 'Preview', element: <Preview /> },
        // { path: 'results', element: <Results /> },
      ],
    },
    { path: '*', element: <NotFound /> },
  ]);
  return routes;
};

export default Routes;
