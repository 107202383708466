import styled, { keyframes } from 'styled-components';
const fadeIn = keyframes`
  0%{
    opacity:0;
    transform:scale(0);
  }
`;

export const Warper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: relative;
`;

export const Content = styled.div`
  top: 50%;
  left: 50%;
  max-width: 565px;
  min-width: 300px;
  margin: 4rem auto;
  border-radius: 1rem;
  border: 1px solid var(--secondary-border);
  box-shadow: 0 3px 6px rgb(0, 0, 0, 0.16);
  animation: ${fadeIn} ease-in-out 450ms;
  transform-origin: bottom;
  @media (max-width: 800px) {
    max-width: 80%;
  }
  @media (max-width: 500px) {
    max-width: 95%;
  }
`;

export const Logo = styled.img`
  display: block;
  margin: 0rem auto;
  padding-top: 4rem;
  left: 50%;
  width: 332.21px;
  animation: ${fadeIn} ease-in-out 450ms;
  transform-origin: top;
`;
