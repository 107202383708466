import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Content, Logo, Warper } from './Auth.style';
import imgLogo from '../../Assets/logo2x.png';

const Auth: FC = () => {
  return (
    <Warper>
      <Logo src={imgLogo} />
      <Content>
        {/* auth child login // forgot password reset password */}
        <Outlet />
      </Content>
    </Warper>
  );
};

export default Auth;
