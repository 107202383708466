import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Core/Store/store';

interface IToaster {
  showToaster: boolean;
  state: string;
  message: string;
}

//initial state for Toaster info
const initialState: IToaster = {
  showToaster: false,
  state: '',
  message: '',
};

/**
 * @todo
 * create my reducer && set actions to it
 *
 */
export const toastSlice = createSlice({
  name: 'spinner',
  initialState,
  reducers: {
    // show toaster
    showToast: (state, action) => {
      state = { ...action.payload };
    },
  },
});

// export my actions to dispatch hook
export const { showToast } = toastSlice.actions;

// get my spinner object from my selector hook
export const selectSpinnerState = (state: RootState) => state.toast;

// export reducer to my state
export default toastSlice.reducer;
