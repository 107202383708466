import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Core/Store/store';

export interface IProfile {
  id: number;
  email: string;
  profile: any;
  role: { id: number; name: string };
}

//initial state for profile info
const initialState: { profile: IProfile } = {
  profile: {
    id: 0,
    email: '',
    profile: null,
    role: {
      id: 0,
      name: '',
    },
  },
};

/**
 * @todo
 * create my reducer && set actions to it
 *
 */
export const profileInfoSlice = createSlice({
  name: 'profile',
  initialState,

  reducers: {
    //to show spinner or close it
    addProfileData: (state, action) => {
      state.profile = { ...action.payload };
    },
    removeProfileData: () => initialState,
  },
});

// export my actions to dispatch hook
export const { addProfileData, removeProfileData } = profileInfoSlice.actions;

// get my spinner object from my selector hook
export const selectProfileState = (state: RootState) => state.profile.profile;

// export reducer to my state
export default profileInfoSlice.reducer;
