// Redux Toolkit
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Core/Store/store';

interface IConfirmation {
  title: string;
  body: string;
  id?: string | number;
  open: boolean;
  prefix?: string;
  accept: Function;
  reject: Function;
}

const initialState: { state: IConfirmation } = {
  state: {
    title: '',
    body: '',
    id: 0,
    open: false,
    prefix: '',
    accept: () => {
      return;
    },
    reject: () => {
      return;
    },
  },
};

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    setConfirmation: (state, action) => {
      state.state = { ...action.payload };
    },
    closeConfirmation: () => {
      return initialState;
    },
  },
});

export const { setConfirmation, closeConfirmation } = confirmationSlice.actions;
export const selectConfirmationState = (state: RootState) => state.confirmation.state;
export default confirmationSlice.reducer;
