import { useAppSelector } from '../../Core/Store/hooks';
import { selectLoading } from '../../Service/State/spinnerSlice/spinnerSlice';
import { Root } from './Loading.Style';

const Loading = () => {
  const loading = useAppSelector(selectLoading);
  return (
    <>
      {loading && (
        <Root>
          <div className="loader" />
        </Root>
      )}
    </>
  );
};

export default Loading;
