import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../Core/Store/store';
import { userLogin } from './auth.api';
import { IAuth } from './auth.interface';

//initial state for auth info
const initialState: IAuth = {
  token: '',
  name: '',
  role: '',
  isAuth: false,
};

export const fetchLogin = createAsyncThunk('users/fetchByIdStatus', async (userData: any) => {
  const response = await userLogin(userData);
  return response.data;
});

/**
 * @todo
 * create my reducer && set actions to it
 *
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // when sign in get my data to my reducer
    signIn: (state, action) => {
      localStorage.setItem('token', JSON.stringify(action.payload));
      state.isAuth = true;
      state.token = action.payload.access_token;
    },
    // when sign out return my state to initial state
    signOut: () => {
      return initialState;
    },
  },
});

// export my actions to dispatch hook
export const { signIn, signOut } = authSlice.actions;

// get my auth object from my selector hook
export const selectAuth = (state: RootState) => state.auth;

// export reducer to my state
export default authSlice.reducer;
