import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root{
        --primary-color:#00435A;
        --hover-color:#016586 ;
        --primary-border-color:#0f5168;
        --focus-ring:#B5C8CF;
        --background-color:#fff;
        --secondary-background-color:rgba(255, 255, 255, 0.33);
        --secondary-border:rgb(112, 112, 112,.33);
        --body-color:#F5F5F5;
        --select-color:#E9E9E9;
        --success-color:#3A9E52;
        --info-color:##F29423;
        

    }
    *{
        padding: 0;
        margin: 0;
    }
    html{
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        box-sizing: border-box;
    }
    body {
        box-sizing: border-box;
        background-color: var(--body-color);
        overflow: hidden;
    }
    .p-panelmenu-header-link {
        background-color:  var(--primary-color) !important;
        color: #fff !important;
        border-radius: 0px 8px 8px 0px !important;
        height: 80px !important;
    }
    .p-panelmenu-header-link:hover {
        background-color: var(--hover-color) !important;
    }
    .p-menuitem{
        min-height: 40px;
    }
    .p-checkbox .p-checkbox-box{
        border-radius: 5px !important;
        width: 21px;
        height: 21px;
        border-color: rgba(166, 166, 166, 0.5) !important;

    }
    .p-accordion-content{
        background-color: transparent !important;
    }
    .p-button.p-button-info{
        background:var(--primary-color);
        border-color:var(--primary-border-color);
    }
    .p-button.p-button-icon-only{
        min-width: 2.357rem;
    }
    .p-button.p-button-custom{
        background:var(--primary-color);
        border-color:var(--primary-border-color);
        
    }
    .p-datatable .p-datatable-tbody > tr > td {
        max-height: 4.5rem;
        overflow: hidden;
        overflow-y: hidden;
    }
    img[src="chrome-extension://ohcpnigalekghcmgcdcenkpelffpdolg/img/icon16.png"]{
        display: none
    }
`;
