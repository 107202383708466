import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { GlobalStyle } from './App.style';

import './App.css';
import Loading from './Components/Loading/Loading';
import Routes from './Core/Routes/Routes';
import Interceptors from './Service/Interceptors/Interceptors';
import ConfirmationDialog from './Components/ConfirmationDialog/ConfirmationDialog';
import Quill from 'quill';

function App() {
  const Font = Quill.import('formats/font');
  Font.whitelist = ['sans-serif', 'monospace', 'serif', 'times-new-roman', 'arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
  Quill.register(Font, true);
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes />
      </Suspense>
      <GlobalStyle />
      <Interceptors />
      <ToastContainer limit={3} position="top-center" />
      <ConfirmationDialog />
      <Loading />
    </Router>
  );
}

export default App;
