import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Core/Store/store';

export interface IEventInfo {
  name: string;
  main_color: string;
  text_color: string;
  company_id: number;
  brand_id: number;
  // start_date: string;
  // end_date: string;
  logo: any;
  audio: any;
  show_company_logo: number;
  show_brand_logo: number;
  fav_icon: any;
}
export interface IEventSessions {
  date: string;
  start_time: string;
  end_time: string;
  link: string;
}
export interface IEventCalender {
  start_date: string;
  end_date: string;
  times: IEventSessions[];
}

const initialState: {
  formData: FormData;
  eventInfo: IEventInfo;
  eventCalender: IEventCalender;
} = {
  formData: new FormData(),
  eventInfo: {
    name: '',
    main_color: '',
    text_color: '',
    company_id: 0,
    brand_id: 0,
    // start_date: '',
    // end_date: '',
    logo: {},
    fav_icon: {},
    audio: {},
    show_company_logo: 0,
    show_brand_logo: 0,
  },
  eventCalender: {
    start_date: '',
    end_date: '',
    times: [],
  },
};

/**
 * @todo
 * create my reducer && set actions to it
 *
 */
export const eventSlice = createSlice({
  name: 'event',
  initialState,

  reducers: {
    //to show event or close it
    addEventInfo: (state, action) => {
      state.eventInfo = { ...action.payload };
      for (const key in action.payload) {
        const element = action.payload[key];
        state.formData.append(key, element);
      }
    },
    addEventCalender: (state, action) => {
      state.eventCalender = { ...action.payload };
    },
    clearEvent: () => initialState,
  },
});

// export my actions to dispatch hook
export const { addEventInfo, addEventCalender, clearEvent } = eventSlice.actions;

// get my spinner object from my selector hook
export const selectEventInfoState = (state: RootState) => state.event.eventInfo;
export const selectEventFormData = (state: RootState) => state.event.formData;
export const selectEventCalender = (state: RootState) => state.event.eventCalender;

// export reducer to my state
export default eventSlice.reducer;
