import axiosInstance from '../../../Core/AxiosInstance/axiosInstance';

/**
 *
 * @param data {email:string,password:string}
 * @description make api call to login
 * @returns promise of user info data
 */
export const userLogin = async (data: { email: string; password: string }): Promise<any> => {
  const res: any = await axiosInstance.post('/dashboard/login', data);
  return res.data.data;
};

export const forgotPassword = async (data: { email: string }): Promise<any> => {
  const res = await axiosInstance.post('/dashboard/forgot-password', data);
  return res.data.message;
};

export const resetPassword = async (data: { email: string; password: string; password_confirmation: string }, token: string): Promise<any> => {
  const res = await axiosInstance.post(`/dashboard/reset-password?token=${token}`, data);
  return res.data.data;
};
