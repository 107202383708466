import { FC } from 'react';
import { Button } from 'primereact/button';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Label, Root, Title } from '../Login/Login.style';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { resetPassword } from '../../../Service/State/authSlice/auth.api';
import { useAppDispatch } from '../../../Core/Store/hooks';
import { signIn } from '../../../Service/State/authSlice/authSlice';

const ResetPassword: FC = () => {
  // initial value for login form
  const defaultValues: any = {
    email: '',
    password: '',
    password_confirmation: '',
  };

  const params = useParams();

  //navigator to navigate to home if login success
  const navigate = useNavigate();

  //store actions to store credentials to the store
  const dispatch = useAppDispatch();

  //hook form to generate form data
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  //form on submit
  /**
   * @todo
   * make axios call
   * send the data to store from dispatch
   * send the token to localStorage
   * navigate to my home if signIn successful
   * @param
   * email && password
   */
  const onSubmit = async (data: any) => {
    // const userData = await userLogin(data);
    const token: string = params.token ? params.token : '';
    const userData = await resetPassword(data, token);
    dispatch(signIn(userData.token));
    navigate('/');
  };

  const getFormErrorMessage = (name: string) => {
    return errors[`${name}`] && <small className="p-error">{errors ? errors[`${name}`]?.message : null}</small>;
  };

  return (
    <Root>
      <Title fontSize="32px" position="center">
        Welcome to Circle!
      </Title>
      <Title fontSize="24px" position="left">
        Reset Password
      </Title>
      <div className=" form-div">
        <div className="card">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            {/* Email */}
            <div className="p-field">
              <Label
                className={classNames({
                  'p-error': errors[`email`],
                })}
                htmlFor="email"
              >
                Email
              </Label>
              <Controller
                name="email"
                control={control}
                rules={{ required: 'Email is required.' }}
                render={({ field, fieldState }) => (
                  <InputText
                    id="email"
                    type="email"
                    {...field}
                    className={classNames({
                      'p-invalid': fieldState.invalid,
                    })}
                    placeholder="Email"
                  />
                )}
              />
              {getFormErrorMessage('email')}
            </div>

            {/* password */}
            <div className="p-field">
              <Label
                className={classNames({
                  'p-error': errors[`password`],
                })}
                htmlFor="password"
              >
                Password
              </Label>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: 'Password is required.',
                  minLength: { value: 6, message: 'At least 6 digits' },
                }}
                render={({ field, fieldState }) => (
                  <Password
                    id="password"
                    {...field}
                    className={classNames({
                      'p-invalid': fieldState.invalid,
                    })}
                    placeholder="Password"
                    toggleMask
                    feedback={false}
                  />
                )}
              />
              {getFormErrorMessage('password')}
            </div>

            {/* confirm password */}
            <div className="p-field">
              <Label
                className={classNames({
                  'p-error': errors[`confirm-password`],
                })}
                htmlFor="confirm-password"
              >
                Confirm Password
              </Label>
              <Controller
                name="password_confirmation"
                control={control}
                rules={{
                  required: 'Confirm Password is required.',
                  minLength: { value: 6, message: 'At least 6 digits' },
                }}
                render={({ field, fieldState }) => (
                  <Password
                    id="confirm-password"
                    {...field}
                    className={classNames({
                      'p-invalid': fieldState.invalid,
                    })}
                    placeholder="Confirm Password"
                    toggleMask
                    feedback={false}
                  />
                )}
              />
              {getFormErrorMessage('password_confirmation')}
            </div>

            <Button type="submit" label="Reset Password" className="p-mt-4"></Button>
            <Link to="/login">Login</Link>
          </form>
        </div>
      </div>
    </Root>
  );
};

export default ResetPassword;
