import axios from 'axios';

//axios Instance with base url from env
const axiosInstance = axios.create({
  //change the url from .env
  baseURL: process.env.REACT_APP_API_URL,
});

//axios refresh Instance
export const axiosRefresh = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/refresh`,
});

export default axiosInstance;
