import React, { FC } from 'react';
import { closeConfirmation, selectConfirmationState } from '../../Service/State/confirmtionSlice/confirmtionSlice';
import { useAppDispatch, useAppSelector } from '../../Core/Store/hooks';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const ConfirmationDialog: FC = () => {
  const dialogState = useAppSelector(selectConfirmationState);
  const dispatch = useAppDispatch();

  // callback on accept action
  const accept = async () => {
    onHide();
    if (dialogState.accept) await dialogState.accept();
  };

  // callback on reject action
  const reject = async () => {
    onHide();
    if (dialogState.reject) await dialogState.reject();
  };

  // call back when the dialogue hide
  const onHide = () => {
    dispatch(closeConfirmation());
  };

  const renderFooter = () => {
    return (
      <div>
        <Button label="No" icon="pi pi-times" onClick={reject} className=" p-button-text p-button-plain" />
        <Button className=" p-button-info" label="Yes" icon="pi pi-check" onClick={accept} autoFocus />
      </div>
    );
  };

  return (
    <div>
      <Dialog header={dialogState.title} visible={dialogState.open} footer={renderFooter()} onHide={() => onHide()}>
        <p>{dialogState.body}</p>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
