import styled, { keyframes } from 'styled-components';
const shake = keyframes`
  0%{
    transform:translateX(-1px)
  }
  20%{
    transform:translateX(1px)
  }
  60%{
    transform:translateX(-1px)
  }
  80%{
    transform:translateX(1px)
  }
`;

export const Root = styled.div`
  display: grid;
  padding: 35px;
  position: relative;

  a {
    text-align: center;
    color: #000;
    display: block;
    font-size: 18px;
    text-decoration: none;
    :hover {
      animation: ${shake} ease-in-out 450ms;
    }
  }

  div.form-div {
    width: 100%;
    position: 'relative';
  }
  .p-field {
    width: 100%;
  }
  input {
    :hover {
      border: 1px solid var(--hover-color) !important;
    }
    :focus {
      box-shadow: none !important;
      border: 1px solid var(--hover-color) !important;
    }
  }
  button {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    margin: 30px auto;
    width: 70% !important;
    display: block;

    :hover {
      background: var(--hover-color) !important;
      border: 1px solid var(--hover-color) !important;
      transform: translateY(-1px);
    }
    :active {
      background: var(--hover-color) !important;
      border: 1px solid var(--hover-color) !important;
      transform: translateY(0px);
    }
    :focus {
      box-shadow: 0 0 0 0.2rem var(--focus-ring);
    }
  }
`;

export const Title: any = styled.p`
  margin-bottom: 30px !important;
  text-align: ${(props: any) => props.position};
  font-size: ${(props: any) => props.fontSize};
`;

export const FormDiv = styled.div`
  color: #fff;
  z-index: 3;
`;

export const Label = styled.label`
  margin: 10px 60px 9px 4px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
`;
