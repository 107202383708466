import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Core/Store/store';

//initial state for steps info
const initialState: { step: number } = {
  step: 0,
};

/**
 * @todo
 * create my reducer && set actions to it
 *
 */
export const stepSlice = createSlice({
  name: 'step',
  initialState,

  //set step
  reducers: {
    setStep: (state, action) => {
      state = action.payload;
    },
  },
});

// export my actions to dispatch hook
export const { setStep } = stepSlice.actions;

// get my step object from my selector hook
export const selectStep = (state: RootState) => state.step;

// export reducer to my state
export default stepSlice.reducer;
