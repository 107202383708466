import React, { useState } from 'react';
import { Label, Root, Title } from '../Login/Login.style';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { toast } from 'react-toastify';
import axiosInstance from '../../../Core/AxiosInstance/axiosInstance';

const ForgotPassword = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [email, setEmail] = useState<string>('');
  const [formTouched, setFormTouched] = useState<boolean>(false);
  const [error, hasError] = useState<boolean>(false);
  const navigate = useNavigate();
  const onSubmit = async (e: any) => {
    e.preventDefault();
    hasError(true);
    if (!email) {
      setFormTouched(true);
      return;
    }
    try {
      const res = await axiosInstance.post('dashboard/forget-password', { email });
      toast.success(res.data.message, {
        toastId: 'forgot-success',
        position: 'top-center',
      });
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Root>
      <Title fontSize="32px" position="center">
        Welcome to Circle!
      </Title>
      <Title fontSize="24px" position="left">
        Forgot Password
      </Title>
      <div className=" form-div">
        <div className="card">
          <form onSubmit={onSubmit} className="p-fluid">
            <div className="p-field">
              <Label htmlFor="email" className={formTouched && error ? 'p-error' : ''}>
                Email
              </Label>
              <InputText
                id="email"
                placeholder="Email"
                className={formTouched && error ? 'p-invalid' : ''}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                  setFormTouched(!e.target.value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i));
                }}
              />
              {formTouched && error ? <small className="p-error">*Invalid email address. E.g. example@email.com</small> : null}
            </div>
            <Button type="submit" label="Send Verification" className="p-mt-4"></Button>
            <Link to="/login">Login</Link>
          </form>
        </div>
      </div>
    </Root>
  );
};

export default ForgotPassword;
